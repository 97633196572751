.change-by-btn {
    .hide_button {
    display: none;
    justify-content: center;
    align-items: center;
    width: 140px;
    height: 46px;
    margin-left: 40px;
    cursor: pointer;

    border: 1px solid #007DFF;
    background: none;
    color: #007DFF;
    border-radius: 10px;
    cursor: pointer;
  }
}
