@import '../../../../../../styles/variables.scss';
@import '../../../../../../styles/functions.scss';
@import '../../../../../../styles/mixins.scss';

.dashboards-short-info {
  width: 100%;
  padding: size(8px) size(12px);
  min-width: size(150px);
  height: size(80px);
  display: flex;
  //flex-direction: column;
  //align-items: flex-start;
  justify-content: space-between;
  background: #FDFDFF;
  border: size(1px) solid $color-ghost-white-2;
  border-radius: size(10px);
  cursor: pointer;
  position: relative;

  &:hover {
    background: linear-gradient(0deg, #FFFFFF, #FFFFFF), #FDFDFF;
    border: size(1px) solid rgba(67, 88, 117, 0.12);
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
  }
  &-plan {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
    padding: size(5px) 0 size(6px);
    position: relative;
    margin-left: size(3px);
    &__current,
    &__previous {
      color: #8E9EB5;
      font-size: size(14px);
      font-weight: 500;
      white-space: nowrap;
      &.red {
        color: #E56384;
      }
      &.green {
        color: #2DCE8A;
      }
      &:hover + .dashboards-short-info-plan-modal {
         display: flex;
        position: absolute;
        z-index: 10;
        top: size(23px);
        right: size(-240px);
        &.right {
          right: 0;
        }
      }
    }
    &-modal {
      display: none;
      width: size(250px);
      background: #F9FAFF;
      border: size(1px) solid #43587508;
      box-shadow: 0 4px 24px 0 #0000000A;
      padding: size(8px) size(22px);
      border-radius: size(10px);
      flex-direction: column;
      align-items: flex-start;

      &.active {
        display: flex;
        position: absolute;
        z-index: 10;
        top: size(23px);
        right: size(-240px);
        &.right {
          right: size(0px);
        }
      }

      &__title {
        font-size: size(12px);
        font-weight: 400;
        color: #435875;
        margin-bottom: size(4px);

        span {
          color: #8E9EB5;
          margin-left: size(4px);
          font-size: size(12px);
          font-weight: 500;
          line-height: size(15px);

          &.red {
            color: #E56384;
          }
          &.green {
            color: #2DCE8A;
          }
        }
      }
      &__text {
        font-size: size(10px);
        font-weight: 400;
        line-height: size(12px);
        text-align: left;
        color: #435875;
        margin: 0 0 size(8px);
      }
      &__date {
        color: #8E9EB5;
        font-size: size(10px);
        font-weight: 400;
        line-height: size(12px);
      }
    }
    //&:hover {
    //  .dashboards-short-info-plan-modal {
    //    display: flex;
    //    position: absolute;
    //    z-index: 10;
    //    top: size(23px);
    //    right: size(-240px);
    //    &.right {
    //      right: 0;
    //    }
    //  }
    //}
  }

  &__title {
    color: #8E9EB5;
    font-weight: 500;
    font-size: size(12px);
    margin-bottom: size(2px);
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    white-space: nowrap;
  }

  &__value {
    font-weight: 400;
    font-size: size(26px);
    line-height: size(31px);
    color: #435875;
    position: relative;
    white-space: nowrap;

    span {
      font-size: size(14px);
      margin-left: size(4px);

      &.percent {
        font-weight: 300;
        font-size: size(26px);
        color: #435875;
        opacity: .8;
      }
    }
    &:hover .dashboards-short-info__full{
      display: flex;
    }
  }
  &__full {
    position: absolute;
    max-width: 250px;
    width: max-content;
    left: size(7px);
    top: size(-24px);
    z-index: 1000;
    display: none;

    span {
      padding: 6px 8px;
      background: #EFF0F9;
      font-size: size(16px);
      border-radius: size(4px) size(4px) size(4px) 0;
      display: flex;
      align-items: center;
      position: relative;
      font-weight: 400;
      line-height: 12px;
      color: #435875;

      &:after {
        content: '';
        position: absolute;
        bottom: size(-5px);
        left: 0;
        display: flex;
        width: size(6px);
        height: size(5px);
        background: #EFF0F9;
        clip-path: polygon(0 0, 0 100%, 100% 0);
        border-radius: 0 0 0 size(3px);
      }
    }
  }
}