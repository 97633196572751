@import '../../../../styles/variables.scss';
@import '../../../../styles/functions.scss';
@import '../../../../styles/mixins.scss';

.table-funnel {
  &-header {
    display: flex;

    border-bottom: 2px solid $color-ghost-white-2;
    border-radius: 12px 12px 0 0;

    width: 100%;

    &__view {
      display: flex;
      align-items: center;
      width: size(310px);
      min-width: size(310px);
      margin-left: size(26px);
      .select__head {
        width: 100%;
      }
      .select__block {
        height: size(42px);
        //width: 100%;
      }
    }

    .custom-input {
      &__field {
        background: $color-ghost-white;
        &:hover,
        &:focus,
        &:active {
          background: $color-ghost-white;
          color: $color-chambray;
        }
      }
      &:hover,
      &:focus,
      &:active,
      &.active {
        .custom-input {
          &__field {
            background: $color-ghost-white;
            color: $color-chambray;
          }
          &__icon {
            path {
              fill: $color-chambray;
            }
          }
        }
      }
    }
    &__options {
      .filter__wrapper {
        right: size(-2px);
        padding: 0;
        border-radius: size(12px) 0 size(12px) size(12px);
        border: size(2px) solid $color-ghost-white-2;
        height: auto;
        .title {
          height: size(54px);
          display: flex;
          align-items: center;
          padding: 0 size(30px);
          border-bottom: size(2px) solid $color-ghost-white-2;
          margin-bottom: 0;
        }
        form, .form {
          padding: size(22px) size(30px);
        }
      }
      .dropdown-list {
        z-index: 10;
      }
    }
  }
}