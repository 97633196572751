@import '../../styles/variables.scss';
@import '../../styles/functions.scss';
@import '../../styles/mixins.scss';

.filter {
  position: relative;
  height: 100%;

  .title {
    padding: 0 size(30px);
    margin-bottom: size(32px);
    border-bottom: 2px solid $color-ghost-white-2;
    height: size(50px);
    display: flex;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: $color-ghost-white;
  }

  &__wrapper {
    position: absolute;
    top: size(56px);
    //right: -106%;

    width: size(362px);
    //height: calc(100vh - #{size(148px)});
    //height: calc(100 * var(--vh, 1vh) - #{size(148px)});
    //padding: 0 0 size(18px) 0;

    background-color: $color-ghost-white;
    border: 2px solid $color-ghost-white-2;
    border-bottom: none;

    z-index: 5;
    opacity: 1;
    visibility: hidden;
    pointer-events: none;

    overflow-y: auto;
    overflow-x: hidden;

    right: size(-2px);
    padding: 0;
    border-radius: size(12px) 0 size(12px) size(12px);
    border-bottom: size(2px) solid $color-ghost-white-2;
    height: auto;
    .title {
      //height: size(54px);
      display: flex;
      align-items: center;
      padding: 0 size(30px);
      border-bottom: size(2px) solid $color-ghost-white-2;
      margin-bottom: 0;
    }

    &.scrolled {
      max-height: size(586px);
    }

    /*Кастомный скроллбар*/

    @include custom-scroll;

    transition: visibility $trans-default, opacity $trans-default;
  }

  .form, form {
    display: flex;
    flex-direction: column;
    //padding: 0 size(20px) 0 size(21px);
    padding: size(22px) size(30px);

    fieldset {
      border: none;
      padding: 0;
      margin: 0 0 size(12px);

      &:last-of-type {
        margin: 0 0 size(11px);
      }
      .select__block {
        max-width: size(317px);
      }
    }

    legend {
      margin: 0 0 size(8px);
    }

    .custom-toggle {
      padding: 0 0 size(10px);
      font-size: size(14px);
    }
  }

  .btn {
    margin-bottom: size(9px);
  }

  &__input {
    margin-bottom: 0;
  }

  &.is-active {
    .filter__wrapper {
      opacity: 1;
      visibility: visible;
      pointer-events: auto;
    }
  }
  &__bottom {
    display: flex;
    flex-direction: column;
    //margin-top: size(20px);
  }
}
