@import '../../../styles/variables.scss';
@import '../../../styles/functions.scss';
@import '../../../styles/mixins.scss';

.upload-file {
  width: 100%;
  margin-bottom: size(20px);
  position: relative;
  &-drag {
    max-width: 100%;
    padding: size(22px) 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border: size(2px) solid $color-ghost-white-2;
    border-radius: size(10px);
    position: relative;
    &__title {
      font-weight: 400;
      font-size: size(16px);
      padding: 0;
      margin: 0 0 size(12px) 0;
      color: #435875;
    }
    label {
      cursor: pointer;
      margin: 0 0 size(12px) 0;
      z-index: 2;
    }
    &__text {
      font-weight: 400;
      font-size: size(14px);
      color: #8E9EB5;
    }
    &__formUpload {
      font-size: size(18px);
      display: block;
      width: 100%;
      border: none;
      text-transform: none;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      opacity: 0;
      z-index: 1;
      :focus {
        outline: none;
      }
    }
  }
  &-preview {
    width: 100%;
    p {
      font-weight: 400;
      font-size: size(16px);
      display: flex;
      align-items: center;
      color: #435875;
      margin-bottom: size(12px);
    }

    &__block {
      width: 100%;
      background: #EDF3FC;
      border-radius: size(8px);
      display: flex;
      align-items: center;
      padding: size(17px);
      font-size: size(14px);
      line-height: size(17px);
      color: #435875;
      margin-bottom: size(12px);
      span {
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
        white-space: nowrap;
      }
      img {
        padding: 0;
        margin: 0 size(17px) 0 0;
      }
      button {
        display: flex;
        align-items: center;
        justify-content: center;
        outline: none;
        background: transparent;
        margin-left: auto;
        width: size(20px);
        height: size(20px);
        border: none;
        padding: 0;
        cursor: pointer;
        border-radius: size(6px);
        img {
          margin: 0;
          padding: 0;
          width: size(12px);
        }
        &:hover {
          background: #E1EDFF;
        }
      }
    }
    .btn {
      width: 100%;
    }
  }
  &--invalid {
    .upload-file {
      &-drag {
        border-color: $color-pink-lace;
      }
    }
  }
  &-error {
    //position: absolute;
    //left: 0;
    //bottom: size(-3px);
    color: $color-torch-red;

    //transform: translateY(size(18px));

    user-select: none;
    pointer-events: none;
    transition: opacity $trans-default;
    margin: 0;
    position: absolute;
    bottom: size(-6px);
    background: $color-ghost-white;
    transform: translate(-50%, 0);
    left: 50%;
  }
}