@import '../../../../../styles/variables.scss';
@import '../../../../../styles/functions.scss';
@import '../../../../../styles/mixins.scss';

.page-content__content_scroll {
  overflow-y: auto;
}
.project {
  &-filter {
    display: flex;
    align-items: center;
    margin-bottom: size(12px);
    position: fixed;
    z-index: 99;
    background: #f9faff;
    max-width: calc(100vw - #{size(154px)});

    &__date {
      min-width: size(303px);
      width: max-content;
      height: size(50px);
      background: #EDF3FC;
      border-radius: size(10px);
      margin-right: size(12px);
      display: flex;
      align-items: center;
      color: #007BFF;
      & img {
        margin: 0 size(12px) 0 0;
      }
      & .input {
        margin-bottom: 0;
        margin-top: 0;
      }
    }
    &__input {
      .custom-input__icon, .custom-textarea__icon {
        right: auto;
        left: size(-15px);
      }
      & > label input,
      label textarea,
      label input,
      label textarea {
        color: #007BFF;
        font-size: size(16px);
        &:hover,
        &:focus {
          background: transparent;
        }
        &::placeholder {
          color: #007BFF;
        }
      }
    }
    &__model {
      min-width: size(257px);
      width: max-content;
      margin-right: size(12px);
      .select__block {
        padding-right: size(40px);
      }
    }
    &__window {
      min-width: size(325px);
      width: max-content;
      margin-right: size(12px);
      .select__block {
        padding-right: size(40px);
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
  &-summary {
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    align-content: stretch;
    width: 100%;
    flex-wrap: wrap;
    margin-top: size(62px);
    &__item {
      width: calc((100% - (#{size(12px)}*5)) / 6);
      min-width: size(150px);
      margin-right: size(12px);
      margin-bottom: size(12px);
      height: auto;
      cursor: pointer;
      position: relative;
      &:nth-child(6n) {
        margin-right: 0;
      }
    }
  }
  &-pie {
    width: 25%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 12.5rem;
    border: size(1px) solid $color-ghost-white-2;
    border-radius: size(12px);
    margin-bottom: size(12px);
    background: #FDFDFF;
    position: relative;
  }
  &-info {
    margin: size(16px) 0;
    &__title {
      font-weight: 400;
      font-size: size(16px);
      line-height: size(19px);

      color: #435875;
      margin: 0 0 size(2px) 0;
      padding: 0;
    }

    &__text {
      font-weight: 500;
      font-size: size(16px);
      line-height: size(19px);
      margin: 0;
      padding: 0;
      color: #8E9EB5;
    }
  }
  &-block {
    display: flex;
    justify-content: space-between;
    &__item {
      width: calc((100% - (#{size(12px)})) / 2);
      min-height: size(200px);
      border: size(1px) solid $color-ghost-white-2;
      border-radius: size(12px);
      margin-bottom: size(12px);
      background: #FDFDFF;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      &__diagram {
        padding: size(10px);
        width: 100%;
      }
      & .table-pagination {
        border: none;
      }
    }
    &.size-l {
      .project-block__item {
        width: calc((100% - (#{size(12px)}*2)) / 3);
      }
    }
    &.size-s {
      .project-block__item {
        &:first-child {
          width: calc((100% - (#{size(12px)})) / 4);
        }
        width: calc((100% - (#{size(12px)})) / 4*3);
      }
    }
  }
  &-table {
    width: 100%;
    //min-height: size(200px);
    position: relative;
    margin-bottom: size(12px);
  }

  &__table {
    width: 100%;
    height: 100%;
    min-height: size(200px);
    position: relative;
    border-radius: size(12px);
    display: flex;
    flex-direction: column;

    & .table-pagination {
      margin-bottom: 0;
      border-radius: size(12px) size(12px) 0 0;
    }
  }
  &__diagram {
    padding: size(10px);
    width: 100%;
  }

  &-loader {
    display: flex;
    align-items: center;
    width: 100%;
    min-height: size(300px);
    position: relative;
    margin-bottom: size(12px);
  }
}