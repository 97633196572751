@import '../styles/variables.scss';
@import '../styles/functions.scss';
@import '../styles/mixins.scss';

.base {
  &__alone {
    overflow-y: auto;
    margin-left: size(90px);
  }
  &-label {
    padding-left: size(26px);
    margin-bottom: size(30px);
    font-family: $ff-averta;
    h1 {
      margin: 0 0 size(3px) 0;
      padding: 0;
      font-size: size(24px);
      color: $color-chambray;
      font-weight: 400;
    }
    p {
      margin: 0;
      padding: 0;
      color: $color-rock-blue;
      font-size: size(14px);
    }
  }
}