@import '../../../../../styles/variables.scss';
@import '../../../../../styles/functions.scss';
@import '../../../../../styles/mixins.scss';

.setting {
  position: relative;
  max-height: 100%;
  overflow-y: auto;
  padding: size(40px) size(16px) 0 size(16px);

  @include custom-scroll;

  &__select-wrapper {
    .select {
      &__block {
        padding-right: 40px;
        border: 1px solid #007BFF;
      }

      &__content {
        overflow-y: auto;
        max-height: 270px;
      }
    }
  }
  &__btns {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &-item {
    &-change {
      &__input {
        .custom-input__field {
          background: transparent;
          &:hover,
          &:focus {
            background: transparent;
          }
        }
      }
      &__submit {
        background: $color-dodger-blue;

      }
    }
  }
  .upload-file {
    margin-bottom: 0;
  }
  &__accordion {
    .accordion__btn {
      font-size: size(18px);
      text-transform: uppercase;
      color: #8E9EB5;
      padding-left: size(16px);
    }
    &.is-active .accordion__content {
      padding: 0 0 size(20px) 0;
    }
  }
  &__bottom {
    position: sticky;
    bottom: 0;
    z-index: 2;
    height: size(90px);
    display: flex;
    align-items: center;
    background: $color-ghost-white;
    //border-top: 2px solid $color-ghost-white-2;
  }
  &__submit {
    padding: size(16px) size(32px);
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: max-content;
    max-height: size(50px);
    border: none;
    border-radius: size(10px);
    background: #ECF3FD;
    color: rgba(0, 123, 255, 1);
    //margin-top: size(20px);
    cursor: pointer;
    white-space: nowrap;
    &.disabled {
      background: #F0F2F9;
      color: #627791;
    }
  }

}