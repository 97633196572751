@import '../../../../styles/variables.scss';
@import '../../../../styles/functions.scss';
@import '../../../../styles/mixins.scss';

.table-interactive-body {
  max-height: size(590px);
  @include custom-scroll;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
    background-color: transparent;
  }

  &.not-found {
    min-height: size(207px);
  }

  &-row {
    display: flex;
    //align-items: center;
    justify-content: space-between;
    height: size(42px);
    &__item {
      border-bottom: size(1px) solid $color-ghost-white-2;
      border-right: size(1px) solid $color-ghost-white-2;
      color: #435875;
      font-size: size(14px);
      padding: size(12px) size(6px);
      //padding: 0;
      //transition: .3s linear;
      //max-width: 30%;
      min-width: size(150px);
      max-width: size(150px);
      width: size(150px);
      background: #FDFDFF;
      position: relative;
      &:first-child {
        padding-left: size(23px);
      }
      &.red {
        padding: size(12px) size(8px);
        .dashboard-output__text {
          background: rgba(229, 99, 132, 0.2);
          border-radius: size(10px);
          width: max-content;
          padding: 0 size(10px);
          height: size(24px);
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      &.yellow {
        padding: size(12px) size(8px);
        .dashboard-output__text {
          background: #FCDA6160;
          border-radius: size(10px);
          width: max-content;
          padding: 0 size(10px);
          height: size(24px);
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      &.date-value {
        text-align: left;
      }
      &.string-value {
        text-align: left;
      }
      &.sticky {
        position: sticky;
        z-index: 1;
        left: 0;
      }
      .alt {
        display: none;
        //top: size(-23px);
        top: auto;
        bottom: size(26px);
        max-width: size(400px);
      }
      &:hover {
        .alt {
          display: flex;
        }
      }
    }
  }
  &-update {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 100;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, .6);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    &__block {
      background: #fdfdff;
      padding: size(20px);
      border: size(1px) solid #EFF0F9;
      border-radius: size(12px);
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      p {
        padding: 0;
        font-weight: 500;
        font-size: size(15px);
        color: #8E9EB5;
        margin: 0 0 size(12px) 0;
      }
    }
  }
}