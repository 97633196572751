@import '../../styles/variables.scss';
@import '../../styles/functions.scss';
@import '../../styles/mixins.scss';

.not-founds-elements {
  font-family: $ff-averta;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  justify-content: center;
  &__title {
    font-size: size(33px);
    font-weight: 400;
    margin: 0 0 size(35px) 0;
    color: $color-nero;
    text-align: center;
    line-height: normal;
  }
  &__action {
    font-family: $ff-averta;
    min-width: size(200px);
  }
  &__link {
    background: transparent;
    font-size: size(18px);
    font-weight: 400;

    &:hover {
      background: transparent;
      color: $color-dodger-blue;
    }
  }
}