@import '../../styles/variables.scss';
@import '../../styles/functions.scss';
@import '../../styles/mixins.scss';

.empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  padding: size(20px) 0;
  width: 100%;
  &__img {
    width: size(80px);
    margin-bottom: size(12px);
  }
  &__text {
    font-weight: 500;
    font-size: size(14px);
    color: $color-rock-blue;
    margin-bottom: size(12px);
  }
  &__title {
    color: $color-nero;
    font-size: size(33px);
    margin-bottom: size(20px);
    line-height: normal;
  }
  &__description {
    font-size: size(16px);
    color: $color-rock-blue;
    margin-bottom: size(40px);
    line-height: normal;
    text-align: center;
  }
}