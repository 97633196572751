@import '../../../styles/variables.scss';
@import '../../../styles/functions.scss';
@import '../../../styles/mixins.scss';

.input-values {
  margin-bottom: 20px;
  &__list {
    display: flex;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
    margin-top: 10px;

    li {
      display: flex;
      align-items: center;
      border: 1px solid #eff0f9;
      border-radius: 10px;
      height: size(32px);
      background: rgba(67, 88, 117, 0.05);
      max-width: 100%;
      span {
        font-size: 16px;
        color: $color-chambray;
        padding: 0 10px;
        border-radius: size(10px) 0 0 size(10px);
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;

        //display: flex;
        //align-items: center;
        display: block;
        text-align: center;
        //height: 100%;
      }
      button {
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        width: size(30px);
        min-width: size(30px);
        height: size(30px);
        padding: 0;
        cursor: pointer;
        margin-left: auto;
        border-radius: size(6px);
        background: rgba(67, 88, 117, 0.05);
        &:hover {
          background: linear-gradient(0deg, rgba(0, 123, 255, 0.05), rgba(0, 123, 255, 0.05)), #EDF3FC;
        }
        img {
          padding: 0;
          margin: 0;
          width: size(15px);
        }
      }
    }
  }
  .select-search__action {
    word-wrap: anywhere;
  }
}