@import '../../../../styles/variables.scss';
@import '../../../../styles/functions.scss';
@import '../../../../styles/mixins.scss';

.table-funnel {
  &-labels {
    z-index: 2;
    position: sticky;
    top: 0;
    display: flex;

    border-bottom: size(2px) solid $color-ghost-white-2;
    padding: 0 0 0 size(24px);
    height: size(54px);
    min-height: size(54px);
    min-width: max-content;
    background: $color-ghost-white;

    &__stretch {

    }

    &__items {
      display: flex;
      //justify-content: space-between;
    }
  }
}
