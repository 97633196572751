@import '../../../styles/variables.scss';
@import '../../../styles/functions.scss';
@import '../../../styles/mixins.scss';

.table-funnel {
  display: flex;
  flex-direction: column;
  height: 100%;
  &-header {
    display: flex;

    border-bottom: 2px solid $color-ghost-white-2;
    border-radius: 12px 12px 0 0;

    width: 100%;
  }
  &-choice {
    display: flex;
    align-items: center;
    position: sticky;
    left: size(24px);
    background: $color-ghost-white;
    .custom-toggle {
      padding: 0;
      label {
        margin: 0;
      }
    }
  }
  &-setting {
    display: flex;
    align-items: center;
    width: size(120px);
    min-width: size(120px);
    border-left: size(2px) solid $color-ghost-white-2;
    margin-left: auto;
    position: sticky;
    z-index: 1;
    right: 0;
    background: $color-ghost-white;
    &.active {
      z-index: 2;
    }
    &__metrics {
      margin-left: size(6px);
      .select__block {
        display: flex;
        align-items: center;
        justify-content: center;
        background: $color-ghost-white;
        padding: 0;
        width: size(40px);
        height: size(40px);
        &:after,
        &:before {
          display: none;
        }
        &:hover,
        &:focus,
        &.active {
          background: $color-alice-blue;
        }
      }
      .select__content.active {
        top: size(47px)
      }

    }
    &__popup {
      margin-left: size(6px);
      & > .btn {
        background: $color-ghost-white;
        padding: 0;
        width: size(40px);
        height: size(40px);
        &:hover,
        &:focus,
        &.active {
          background: $color-alice-blue;
        }
      }
      &.is-active {
        & > .btn {
          background: $color-alice-blue;
        }
      }
      .dropdown-list {
        right: 0;
        width: max-content;
        min-width: auto;
        padding: size(16px);
        &__item {
          margin-bottom: size(4px);
        }
        .btn {
          justify-content: flex-start;
        }
      }
    }
  }
  &__stretch {
    cursor: col-resize;
    width: size(6px);
    border: none;
    border-right: size(1px) solid $color-ghost-white-2;
    background: transparent;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
  }
  &__wrapper {
    overflow-x: auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    @include custom-scroll;
  }
}
