@import '../../styles/variables.scss';
@import '../../styles/functions.scss';
@import '../../styles/mixins.scss';
.reports-create {
  padding: size(40px);
  display: flex;
  //height: 100%;
  overflow-y: auto;
  height: inherit;

  .custom-input {
    margin-bottom: 0;
  }

  @include custom-scroll;
  &__block {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
    width: 43%;
    margin-right: size(40px);
    h1 {
      font-weight: 400;
      font-size: size(22px);
      line-height: size(27px);
      color: #435875;
      margin: 0 0 size(12px) 0;
    }
  }
  &__description {
    font-weight: 400;
    font-size: size(16px);
    line-height: size(19px);
    color: #435875;
    padding: 0;
    margin: 0 0 size(32px) 0;
  }
  &__base {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  &__field {
    width: calc(50% - #{size(11px)});
    margin-bottom: size(22px);
    position: relative;
    &_auto {
      margin-right: size(8px);
      margin-bottom: size(8px);
      width: auto;
      max-width: 100%;
      position: relative;
    }
    &_last {
      margin-bottom: 0;
    }
  }
  &-box {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: size(22px);
    h3 {
      font-weight: 400;
      font-size: size(16px);
      line-height: size(16px);
      color: #435875;
      margin: 0 0 size(8px) 0;
      span {
        color: #E56384;
      }
    }
    .filter-dashboards-item {
      margin-bottom: 0;
    }
    &__block {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      justify-content: space-between;
      padding: size(12px);
      width: 100%;
      border: size(1px) solid rgba(67, 88, 117, 0.12);
      border-radius: size(8px);
      &_start {
        justify-content: flex-start;
        padding-bottom: size(4px);
      }
    }
    &__settings {
      .reports-create__block {
        gap: size(22px);
      }
      .reports-create__field {
        margin-bottom: 0;
      }
    }
    &__btn-add {
      height: size(32px);
      width: max-content;
      padding: 0 size(12px);
      margin-bottom: size(8px);
    }
    &__field {
      display: flex;
      align-items: center;
      border: none;
      outline: none;
      background: transparent;
      .select {
        width: calc(100% - #{size(50px)});
        &__block {
          height: size(32px);
          border-radius: 0;
          background: rgba(67, 88, 117, 0.05);
          width: 100%;
        }
        &__content.active {
          top: size(36px);
          &.top {
            top: auto;
            bottom: size(36px);
          }
        }
        &__search_input {
          width: 100%;
          svg {
            width: size(16px);
            height: size(16px);
          }
        }
        &-inner {
          margin-left: size(6px);
          max-width: 62%;
          .select__block {
            height: size(26px);
            border-radius: size(6px);
          }
          &__title {
            white-space: nowrap;
            max-width: 100%;
          }
        }
      }
    }
    &__drag {
      display: block;
      border: none;
      cursor: grab;
      padding: 0;
      background: rgba(67, 88, 117, 0.05);
      height: size(32px);
      border-radius: size(6px) 0 0 size(6px);
      svg {
        width: size(20px);
        height: size(20px);
        opacity: .4;
      }
    }
    &__filter {
      height: size(32px);
      background: rgba(67, 88, 117, 0.05);
      color: $color-chambray;
      border-radius: size(10px) 0 0 size(10px);
      display: flex;
      align-items: center;
      padding: 0 size(20px);
    }
    &__title {
      display: flex;
      font-size: size(16px);
      line-height: size(19px);
      color: #435875;
      margin-bottom: size(10px);
    }
    &__add {
      &-select {
        display: flex;
        align-items: center;
        width: 100%;
        background: #EDF3FC;
        position: relative;
        border: none;
        cursor: pointer;
        margin: 0;
        font-weight: 400;
        height: size(48px);
        border-radius: size(10px);
        padding-left: size(20px);
        padding-right: size(10px);
        color: #435875;
        font-size: size(16px);
        line-height: size(19px);

        .select__block {
          height: size(35px);
          padding-left: size(10px);
          margin-left: size(10px);
        }

        .select__content.active {
          top: size(38px);

          &.top {
            top: auto;
            bottom: size(38px);
          }
        }
      }
      &-remove {
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        width: size(30px);
        min-width: size(30px);
        height: size(30px);
        padding: 0;
        cursor: pointer;
        margin-left: auto;
        border-radius: size(6px);
        background: transparent;
        &:hover {
          background: linear-gradient(0deg, rgba(0, 123, 255, 0.05), rgba(0, 123, 255, 0.05)), #EDF3FC;
        }
        img {
          padding: 0;
          margin: 0;
          width: size(15px);
        }
      }
    }
    &__remove {
        outline: none;
        border: none;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0;
        padding: 0;
        cursor: pointer;
        width: size(32px);
        height: size(32px);
        background: rgba(67, 88, 117, 0.08);
        border-radius: 0 size(4px) size(4px) 0;
        img {
          min-width: size(10px);
          width: size(10px);
          margin: 0;
          padding: 0;
        }
      }
  }
  &-email {
    width: 100%;
    display: flex;
    flex-direction: column;
    &__wrapper {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
    }
    &__item {
      width: max-content;
      display: flex;
      align-items: center;
      height: size(32px);
      //border-radius: size(4px);
      margin-right: size(8px);
      margin-bottom: size(16px);
    }
    &__title {
      display: flex;
      align-items: center;
      padding: 0 size(12px);
      height: size(32px);
      background: #EDF3FC;
      border-radius: size(4px) 0 0 size(4px);
      font-weight: 400;
      font-size: size(12px);
      line-height: size(15px);
      color: #303030;
    }
    &__remove {
      display: flex;
      align-items: center;
      justify-content: center;
      width: size(32px);
      height: size(32px);
      background: #8E9EB5;
      border-radius: 0 size(4px) size(4px) 0;
      outline: none;
      border: none;
      cursor: pointer;
      img {
        width: size(16px);
        margin: 0;
        padding: 0;
      }
    }
    &-add {
      display: flex;
      align-items: center;
      position: relative;
      width: 50%;
      label {
        margin: 0;
      }
      &__btn {
        position: absolute;
        top: size(3.5px);
        //transform: translateY(-50%);
        right: size(2px);
        width: 38px;
        height: 38px;
        background: #007BFF;
        border-radius: size(8px);
        border: none;
        color: $color-default-white;
        cursor: pointer;
        svg {
          fill: $color-default-white;
        }
        &:hover {
          background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), $color-dodger-blue;
        }
      }
    }
  }
  &-preview {
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 54%;
    max-width: 54%;
    height: size(500px);

    background: rgba(67, 88, 117, 0.02);
    border: size(1px) solid $color-ghost-white-2;
    border-radius: size(12px);

    .table-server-update {
      border-radius: size(12px);
    }

    &__img {
      width: size(52px);
      margin-bottom: size(12px);
    }
    p {
      font-weight: 400;
      font-size: size(14px);
      line-height: size(18px);
      text-align: center;
      color: #435875;
      max-width: size(180px);
      margin: 0;
      padding: 0;
    }
  }
}

.number-sort-modal.active {
  right: auto;
  left: 0;
}