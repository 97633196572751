@import '../../../../styles/variables.scss';
@import '../../../../styles/functions.scss';
@import '../../../../styles/mixins.scss';

.table-funnel {
  &-body {
    will-change: transform;
    &-row {
      display: flex;
      padding: 0 0 0 size(24px);
      height: size(54px);
      border-bottom: size(2px) solid $color-ghost-white-2;
      background: $color-ghost-white;
      min-width: max-content;
      &.is-link {
        .table-funnel-body-row-item {
          cursor: pointer;
        }
      }
    }
  }
}