@import '../../../../../styles/variables.scss';
@import '../../../../../styles/functions.scss';
@import '../../../../../styles/mixins.scss';

.events-list {
  overflow: hidden;
  border-radius: size(12px) size(12px) 0 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  &__empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    text-align: center;
    position: relative;
    .events-list__add {
      border-radius: 0 0 0 size(12px);
    }
  }
  &__header {
    display: flex;
    .form-search {
      width: calc(100% - #{size(20px)});
      border-bottom: size(2px) solid #EFF0F9;
      & input {
        height: size(60px);
      }
      & input:hover,
      & input:focus, {
        background: transparent;
      }
    }
  }
  &__add {
    width: size(60px);
    border: none;
    background: transparent;
    border-bottom: size(2px) solid #EFF0F9;
    border-left: size(2px) solid #EFF0F9;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 0;
  }
  &__create {
    position: absolute;
    top: 0;
    right: 0;
  }
}

.events-list-table {
    font-size: size(14px);
    width: 100%;
    border-collapse: collapse;
    text-align: left;
    margin: 0 auto;
    &__wrapper {
      max-height: calc(100vh - #{size(120px)} - #{size(60px)});
      overflow-y: auto;
      flex-grow: 1;
    }
    .custom-input {
      //width: 300px;
      input {
        background: #fff;
        border: none;
        font-weight: 500;
        &:hover,
        &:focus {
          background: #fff;
        }
        &::placeholder {
          font-weight: 500;
        }
      }
    }
    th {
      font-weight: 500;
      font-size: size(14px);
      color: #8E9EB5;
      border-bottom: size(2px) solid $color-ghost-white-2;
      padding: size(20px) size(6px);
      cursor: pointer;
      &:first-child {
        padding-left: size(23px);
      }
      span {
        margin-right: size(4px);
      }
      &:hover {
        svg path {
          fill: #8E9EB5;
        }
      }
      &.ascending {
        svg path {
          fill: #007BFF;
        }
      }
      &.descending {
        svg {
          transform: rotate(180deg);
          path {
            fill: #007BFF;
          }
        }
      }
    }
    td {
      border-bottom: size(1px) solid $color-ghost-white-2;
      color: #435875;
      font-size: size(14px);
      padding: size(17px) size(6px);
      font-weight: 400;
      line-height: size(17px);
      transition: .3s linear;
      &:first-child {
        padding-left: size(23px);
      }
      &.red {
        padding: size(12px) size(8px);
        span {
          background: rgba(229, 99, 132, 0.2);
          border-radius: size(10px);
          width: max-content;
          padding: 0 size(10px);
          height: size(24px);
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  &__title {
    display: flex;
    align-items: center;
    width: max-content;
  }
  &__btn {
    background: rgba(67, 88, 117, 0.05);
    border-radius: size(6px);
    width: size(29px);
    height: size(29px);
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    margin: 0;
    padding: 0;
    cursor: pointer;
    img {
      height: size(15px);
      margin: 0;
      padding: 0;
    }
  }
  &__empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: size(20px) 0;
    flex-grow: 1;
    img {
      width: size(80px);
      margin-bottom: size(12px);
    }
    span {
      font-weight: 500;
      font-size: size(14px);
      color: #8E9EB5;
    }
  }


  &__footer {
    display: flex;
    justify-content: space-between;
    padding: 0 size(22px);
    min-height: size(50px);
    height: size(50px);
    border-top: size(1px) solid $color-ghost-white-2;
    margin-top: auto;
  }
  &-count {
    display: flex;
    align-items: center;
    span {
      color: #8E9EB5;
      font-size: size(14px);
      margin-right: size(14px);
    }
    select {
      background: transparent;
      border: none;
    }
    &__select {
      position: relative;
      width: size(45px);
      &_block {
        width: 100%;
        background: transparent;
        display: flex;
        align-items: center;
        color: #435875;
        border: none;
        cursor: pointer;

        &:after {
          content: "";
          position: absolute;
          top: 50%;
          right: 0;
          height: size(1px);
          width: size(10px);
          background: #8E9EB5;
          transform: rotate(-45deg);
        }
        &:before {
          content: "";
          position: absolute;
          top: 50%;
          right: size(7px);
          height: size(1px);
          width: size(10px);
          background: #8E9EB5;
          transform: rotate(45deg);
        }
        &.active {
          &:after {
            transform: rotate(45deg);
          }
          &:before {
            transform: rotate(-45deg);
          }
        }
        & img {
          margin: 0 size(12px) 0 0;
        }
      }
      &_content {
        display: none;
        background: #F9FAFF;
        border: size(1px) solid #EFF0F9;
        box-shadow: 0 size(4px) size(24px) rgba(0, 0, 0, 0.04);
        border-radius: size(10px);
        width: 100%;
        padding: size(5px);
        &.active {
          position: absolute;
          display: flex;
          flex-direction: column;
          bottom: size(22px);
          left: 0;
          z-index: 1;
        }
        button {
          background: transparent;
          border: none;
          border-radius: size(6px);
          text-align: left;
          margin-bottom: size(5px);
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: size(4px) size(2px);
          &:last-child {
            margin-bottom: 0;
          }
          &:hover {
            background: #EDF3FC;
          }
          &.active {
            background: #EDF3FC;
          }
        }
      }
    }
  }
  &-paginator {
    display: flex;
    align-items: center;
    &__arrow {
      width: size(32px);
      height: size(32px);
      border: size(1px) solid $color-ghost-white-2;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      margin: 0 size(18px);
      background: transparent;
      cursor: pointer;
      &:hover {
        border-color: transparent;
        background: #EDF3FC;
        svg {
          color: #435875;
        }
      }
      &:last-child {
        margin-right: 0;
      }

    }
    &__pages {
      display: flex;
      align-items: center;
      li {
        margin-right: size(2px);
        &.active button {
          color: #007BFF;
        }
      }
    }
    &__page {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: size(14px);
      color: #435875;
      border: none;
      padding: 0 size(10px);
      height: size(28px);
      cursor: pointer;
      background: transparent;
      &:hover {
        background: #EDF3FC;
        border-radius: size(4px);
      }
    }
    .more {
      background: rgba(67, 88, 117, 0.03);
      border: size(1px) solid $color-ghost-white-2;
      border-radius: size(4px);
      font-size: size(14px);
      color: #435875;
      width: size(50px);
      height: size(32px);
      text-align: center;
      outline: none;
      &:focus {
        border-color: #007BFF;
      }
    }
  }
  &-value {
    border-radius: 50%;
    width: size(18px);
    height: size(18px);
    display: block;
    &.green {
      background: $color-shamrock-lace;
    }
    &.red {
      background: $color-pink-lace;
    }
  }
}