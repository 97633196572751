@import '../../styles/variables.scss';
@import '../../styles/functions.scss';
@import '../../styles/mixins.scss';

.upload-content {
  &__title {
    font-weight: 400;
    font-size: size(22px);
    line-height: size(27px);
    color: #435875;
    margin: 0 0 size(12px) 0;
  }
  &__subtitle {
    font-weight: 400;
    font-size: size(14px);
    line-height: size(17px);
    color: #435875;
    margin: 0 0 size(22px) 0;
    max-width: size(764px);
  }
  &__info {
    font-weight: 400;
    font-size: size(14px);
    line-height: size(18px);
    color: #8E9EB5;
    margin: 0 0 size(12px) 0;
  }
  &__instruction {
    margin-bottom: size(22px);
    &:last-child {
      margin-bottom: 0;
    }
  }
  &__text {
    margin: 0;
  }
  &__list {
    display: flex;
    flex-direction: column;
    li {

    }
  }
  &__link {
    font-weight: 400;
    font-size: size(14px);
    line-height: size(18px);
    color: #007BFF;
    margin-bottom: size(22px);
    cursor: pointer;
    display: flex;
  }
  &-type {
    display: flex;
    flex-direction: column;
    margin-bottom: size(22px);
    &__item {
      display: flex;
      align-items: center;
      margin-bottom: size(8px);
      cursor: pointer;
      &:last-child {
        margin-bottom: 0;
      }

      input[type="radio"] {
        -webkit-appearance: none;
        appearance: none;
        background-color: transparent;
        margin: 0;

        font: inherit;
        color: #007aff;
        width: size(20px);
        height: size(20px);
        border: size(1px) solid #007aff;
        border-radius: 50%;
        transform: translateY(-0.075em);

        display: flex;
        align-items: center;
        justify-content: center;
      }

      input[type="radio"]::before {
        content: "";
        width: 0.65em;
        height: 0.65em;
        border-radius: 50%;
        transform: scale(0);
        transition: 120ms transform ease-in-out;
        box-shadow: inset 1em 1em #007aff;
      }

      input[type="radio"]:checked::before {
        transform: scale(1);
      }

      span {
        margin-left: size(8px);
        color: #435875;
        font-size: size(16px);
      }
    }
  }
  &-drag {
    max-width: max-content;
    padding: size(22px) size(77px);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border: size(2px) solid $color-ghost-white-2;
    border-radius: size(10px);
    position: relative;
    &__title {
      font-weight: 400;
      font-size: size(16px);
      padding: 0;
      margin: 0 0 size(12px) 0;
      color: #435875;
    }
    label {
      cursor: pointer;
      margin: 0 0 size(12px) 0;
      z-index: 2;
    }
    &__text {
      font-weight: 400;
      font-size: size(14px);
      color: #8E9EB5;
    }
    &__formUpload {
      font-size: size(18px);
      display: block;
      width: 100%;
      border: none;
      text-transform: none;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      opacity: 0;
      z-index: 1;
      :focus {
        outline: none;
      }
    }
  }
  &-preview {
    p {
      font-weight: 400;
      font-size: size(16px);
      display: flex;
      align-items: center;
      color: #435875;
      margin-bottom: size(12px);
    }

    &__block {
      width: size(400px);
      background: #EDF3FC;
      border-radius: size(8px);
      display: flex;
      align-items: center;
      padding: size(17px);
      font-size: size(14px);
      line-height: size(17px);
      color: #435875;
      margin-bottom: size(12px);
      span {
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
        white-space: nowrap;
      }
      img {
        padding: 0;
        margin: 0 size(17px) 0 0;
      }
      button {
        display: flex;
        align-items: center;
        justify-content: center;
        outline: none;
        background: transparent;
        margin-left: auto;
        width: size(20px);
        height: size(20px);
        border: none;
        padding: 0;
        cursor: pointer;
        border-radius: size(6px);
        img {
          margin: 0;
          padding: 0;
          width: size(12px);
        }
        &:hover {
          background: #E1EDFF;
        }
      }
    }
    .btn {
      width: size(400px);
    }
  }
}