@import './variables.scss';
@import './functions.scss';
@import './mixins.scss';

.page-content {
  .container {
    display: flex;
    padding: 0;
  }

  &__nav {
    width: size(90px);
    max-width: size(90px);
    flex-shrink: 0;
    transition: all ease-out 100ms;
  }

  &__content {
    width: 100%;
    //height: calc(100vh - #{size(90px)});
    height: calc(100 * var(--vh, 1vh) - #{size(90px)});

    display: flex;
    flex-direction: column;
    position: relative;
    //padding: 0 size(8px) 0 0;
    //margin-right: size(8px);
    // overflow-y: auto;
    // overflow-x: hidden;

    // /*Кастомный скроллбар*/

    // @include custom-scroll;

    &.is-label {
      height: calc(100 * var(--vh, 1vh) - #{size(90px+71px+2px)});
    }
  }

  &__content-wrapper {
    //height: calc(100vh - #{size(90px)});
    height: calc(100 * var(--vh, 1vh) - #{size(90px)});
  }

  &.stretch {
    transform: translateY(size(-70px));
    .page-content__content {
      height: calc(100 * var(--vh, 1vh) - #{size(20px)});
    }
    .page-content__content-wrapper {
      height: calc(100 * var(--vh, 1vh) - #{size(20px)});
    }
    .base__wrapper {
      height: calc(100vh - #{size(20px)});
    }
  }

  &__header {
    display: flex;

    border-bottom: 2px solid $color-ghost-white-2;
    border-radius: 12px 12px 0 0;

    width: 100%;
  }

  &__content.empty {
    display: flex;
    margin-right: size(20px);
    padding: 0;

    //border: 2px solid $color-ghost-white-2;
    border-radius: size(12px) size(12px) 0 0;
    border-bottom: $color-transparent;
  }


  &__content.scrolled {
    overflow-y: auto;
    overflow-x: hidden;

    /*Кастомный скроллбар*/

    @include custom-scroll;
  }

  &__shadow {
    padding: 0 15px;
  }
}
