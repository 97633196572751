@import '../../../../../styles/variables.scss';
@import '../../../../../styles/functions.scss';
@import '../../../../../styles/mixins.scss';

.table-funnel {
  &-body-row {
    &-item {
      font-weight: 500;
      font-size: size(16px);
      color: $color-chambray;
      display: flex;
      align-items: center;
      //width: size(150px);
      //max-width: size(150px);
      position: relative;
      padding: 0 size(17px);
      background: $color-ghost-white;
      span {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      &.is-archived {
        color: $color-spindle;
      }
      &.stretch {
        border-right: size(1px) solid $color-ghost-white-2;
      }
      &.level {
        &_1{
          padding-left: size(51px);
        }
        &_2{
          padding-left: size(85px);
        }
        &_3{
          padding-left: size(102px);
        }
      }

      &.sticky {
        position: sticky;
        z-index: 1;
        left: 0;
      }

      &__title {
        display: flex;
        align-items: center;
        width: 100%;
        position: relative;
        padding: size(12px) size(6px);

        svg {
          min-width: size(10px);
        }

        &:hover .table-label-item__filter,
        &:hover .table-label-item__pin {
          opacity: 1;
        }

        &.left .select-options.active,
        &.left .number-sort-modal.active {
          right: auto;
          left: 0;
        }

        &-text {
          position: relative;

          & ~ .alt {
            display: flex;
            top: size(-5px);
          }
        }
      }
      &__icon {
        margin-right: size(12px);
        width: size(22px);
        min-width: size(22px);
      }
    }
    &.archive {
      .table-funnel-body-row {
        &-item {
          color: $color-spindle;
        }
      }
    }
  }
}