@import '../../../../styles/variables.scss';
@import '../../../../styles/functions.scss';
@import '../../../../styles/mixins.scss';

.table-label-item {
  font-weight: 500;
  font-size: size(14px);
  color: #8E9EB5;
  min-width: size(150px);
  width: size(150px);
  max-width: size(150px);
  position: relative;
  background: #FDFDFF;
  &:not(:empty) {
    border-bottom: size(2px) solid $color-ghost-white-2;
  }
  &.sticky {
    position: sticky;
    z-index: 1;
    left: 0;
  }
  &__cell {
    display: flex;
    height: 100%;

    &.date {
      .table-label-item__title-text:not(.btn__text) {
        text-align: left;
        width: max-content;
        margin-left: 0;
      }
    }

    .table-label-item__title-text:not(.btn__text) {
        margin-right: size(4px);
        width: max-content;
        text-align: left;
        margin-left: 0;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
      }

    &.search .table-label-item__title {
      padding-left: 0;
      min-width: 30%;

      input {
        padding-left: size(20px);
        padding-right: 0;
      }
    }

    &:first-child .table-label-item__title {
      padding-left: size(23px);
    }

    &.ascending {
      svg path {
        fill: #007BFF;
      }
    }

    &.descending {
      svg {
        transform: rotate(180deg);

        path {
          fill: #007BFF;
        }
      }
    }
  }

  &__title {
    display: flex;
    align-items: center;
    width: 100%;
    position: relative;
    padding: size(12px) size(6px);
    svg {
      min-width: size(10px);
    }
    &:hover .table-label-item__filter,
    &:hover .table-label-item__pin {
      opacity: 1;
    }
    &.left .select-options.active,
    &.left .number-sort-modal.active{
      right: auto;
      left: 0;
    }
    &-text {
      position: relative;
      & ~ .alt {
        display: flex;
        top: size(-5px);
      }
    }
  }
  &__filter {
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    border: none;
    outline: none;
    background: transparent;
    cursor: pointer;
    padding: 0;
    margin-left: size(3px);
    img {
      width: size(15px);
      min-width: size(15px);
      margin: 0;
      padding: 0;
    }
    svg {
      width: size(15px);
      min-width: size(15px);
      margin: 0;
      padding: 0;
    }
    &.active {
      opacity: 1;
    }
  }
  &__pin {
    position: absolute;
    top: 5px;
    right: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    border: none;
    outline: none;
    background: transparent;
    cursor: pointer;
    padding: 0;
    margin-left: size(3px);
    img {
      width: size(15px);
      min-width: size(15px);
      margin: 0;
      padding: 0;
    }
    svg {
      width: size(15px);
      min-width: size(15px);
      margin: 0;
      padding: 0;
      fill: $color-dodger-blue;
    }
    &.active {
      opacity: 1;
      svg {
        fill: $color-dodger-blue;
      }
    }
  }
  &__stretch {
    cursor: col-resize;
    width: size(6px);
    border: none;
    border-right: size(1px) solid $color-ghost-white-2;
    background: transparent;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
  }
  &__search {
    .custom-input__icon {
      left: 0;
    }
    label input {
      background: #FDFDFF;
      border: none;
      font-weight: 500;

      &:hover,
      &:focus {
        background: #FDFDFF;
      }

      &::placeholder {
        font-weight: 500;
      }
    }
  }
}