@import '../../styles/variables.scss';
@import '../../styles/functions.scss';
@import '../../styles/mixins.scss';

.settings_wrapper {
  display: flex;
  flex-direction: column;
  border-width: size(2px) size(2px) 0 size(2px);
  border-style: solid;
  border-color: transparent;
  border-radius: size(12px) size(12px) 0 0;
  min-width: 100%;
  max-width: 100%;
  padding: 0;
  margin: 0;
  height: 100%;
  position: relative;
}

.wrapper_border {
  border-color: #EFF0F9;
}

.settings_container {
  display: flex;
  flex-direction: column;
  //border-width: size(2px) size(2px) 0 size(2px);
  //border-style: solid;
  //border-color: transparent;
  //border-radius: size(12px) size(12px) 0 0;
  min-width: calc(100vw - #{size(120px)});
  max-width: calc(100vw - #{size(120px)});
  padding: 0;
  height: calc(100vh - #{size(90px)});
  position: relative;
}