@import '../../../../styles/variables.scss';
@import '../../../../styles/functions.scss';
@import '../../../../styles/mixins.scss';

.dashboards {
  //padding: size(20px) size(32px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: auto;
  padding-right: size(16px);
  &__content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    max-width: size(1000px);
    margin: 0 auto;
  }
  &__item {
    padding: size(20px);
    width: size(300px);
    height: size(200px);
    background: #F9FAFF;
    border: size(1px) solid $color-ghost-white-2;
    box-shadow: 0 size(71px) size(28px) rgba(0, 0, 0, 0.01), 0px 40px 24px rgba(0, 0, 0, 0.02), 0px 18px 18px rgba(0, 0, 0, 0.03), 0px 4px 10px rgba(0, 0, 0, 0.04), 0px 0px 0px rgba(0, 0, 0, 0.04);
    border-radius: size(12px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: size(12px);
    margin-right: size(12px);
    &:nth-child(3n) {
      margin-right: 0;
    }
    &:hover {
      margin-top: size(-4px);
      transition: all .1s ease-out;
    }
  }
  &__not-found {
    position: relative;
    &:after {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      background: rgba(67, 88, 117, 0.05);
      border-radius: size(12px);
    }
  }
  &__icon {
    width: size(50px);
    height: size(50px);

    background: #E0EDFF;
    border-radius: size(10px);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: size(18px);

    img {
      margin: 0;
      padding: 0;
      height: size(21px);
    }
  }
  &__name {
    color: #435875;
    font-weight: 400;
    font-size: size(16px);
    line-height: size(19px);
    text-align: center;
  }
}


.page-content__content_scroll {
  overflow-y: auto;
}
.dashboard-page {
  &-filter {
    display: flex;
    align-items: center;
    margin-bottom: size(12px);
    position: fixed;
    z-index: 99;
    background: #f9faff;
    max-width: calc(100vw - #{size(154px)});

    &.static {
      position: static;
    }

    &__date {
      min-width: size(265px);
      width: max-content;
      height: size(50px);
      background: #EDF3FC;
      border-radius: size(10px);
      margin-right: size(12px);
      display: flex;
      align-items: center;
      color: #007BFF;
      & img {
        margin: 0 size(8px) 0 0;
        width: size(16px);
      }
      & .input {
        margin-bottom: 0;
        margin-top: 0;
      }
      .select__block {
        span {
          font-size: size(14px);
        }
      }
    }
    &__input {
      .custom-input__icon, .custom-textarea__icon {
        right: auto;
        left: size(-15px);
      }
      & > label input,
      label textarea,
      label input,
      label textarea {
        color: #007BFF;
        font-size: size(16px);
        &:hover,
        &:focus {
          background: transparent;
        }
        &::placeholder {
          color: #007BFF;
        }
      }
    }
    &__select {
      max-width: size(400px);
      min-width: size(200px);
      width: 100%;
      margin-right: size(12px);
      &:last-child {
        margin-right: 0;
      }
      .select__block {
        padding-right: size(35px);
        span {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: size(14px);
        }
        img {
          width: size(16px);
          margin-right: size(8px);
        }
      }
    }
    &__window {
      min-width: size(325px);
      width: max-content;
      margin-right: size(12px);
      position: relative;
      .select__block {
        padding-right: size(40px);
      }
      &:last-child {
        margin-right: 0;
      }
    }
    &__auto {
      width: max-content;
      margin: 0;
      position: relative;
      min-width: size(86px);
      .select__block {
        padding-right: size(40px);
      }
    }
    &__btn {
      width: 100%;
      height: size(48px);
      background: #EDF3FC;
      border-radius: size(10px);
      display: flex;
      align-items: center;
      padding-left: size(20px);
      padding-right: size(45px);
      color: #007BFF;
      position: relative;
      border: none;
      cursor: pointer;
      margin: 0;
      font-size: size(14px);
      &:hover {
        background: linear-gradient(0deg, rgba(0, 123, 255, 0.05), rgba(0, 123, 255, 0.05)), #EDF3FC;
      }
      &.active {
        background: #007BFF;
        color: #fff;
      }
      img {
        width: size(16px);
        margin: 0 size(8px) 0 0;
        padding: 0;
      }
    }
    &__reset {
      position: absolute;
      right: size(5px);
      top: 50%;
      transform:translate(0, -50%);
      z-index: 1;
      width: size(40px);
      height: size(40px);
      padding: 0;
      &:hover {
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0.15)), #007BFF;
      }
    }
  }
  &-modal {
    position: absolute;
    top: 0;
    right: 0;
    width: 450px;
    height: 100%;
    border-left: 2px solid #EFF0F9;
    background: #FDFDFF;
  }
  &-summary {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    flex-wrap: wrap;
    margin-top: size(62px);
    &__item {
      width: calc((100% - (#{size(12px)}*5)) / 6);
      margin-right: size(12px);
      margin-bottom: size(12px);
      position: relative;
      &:nth-child(6n) {
        margin-right: 0;
      }
    }
  }
  &-info {
    margin: size(16px) 0;
    &__title {
      font-weight: 400;
      font-size: size(16px);
      line-height: size(19px);

      color: #435875;
      margin: 0 0 size(2px) 0;
      padding: 0;
    }

    &__text {
      font-weight: 500;
      font-size: size(16px);
      line-height: size(19px);
      margin: 0;
      padding: 0;
      color: #8E9EB5;
    }
  }
  &-block {
    display: flex;
    justify-content: space-between;
    &__item {
      width: calc((100% - (#{size(12px)})) / 2);
      min-height: size(200px);
      border: size(1px) solid $color-ghost-white-2;
      border-radius: size(12px);
      margin-bottom: size(12px);
      margin-right: size(12px);
      background: #FDFDFF;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      //overflow-x: auto;
      &:last-child {
        margin-right: 0;
      }
      &__diagram {
        padding: size(10px);
        width: 100%;
      }
      & .table-pagination {
        border: none;
      }
      &.short .table-pagination .table-pagination__agg .select--width-auto .select__block span {
        max-width: size(250px);
      }
      .dashboard-page-table {
        margin-bottom: 0;
        height: 100%;
      }
    }
    &.size-l {
      .project-block__item {
        width: calc((100% - (#{size(12px)}*2)) / 3);
      }
    }
    &.size-full {
      .dashboard-page-block__item {
        width: 100%;
      }
    }
  }
  &-table {
    width: 100%;
    //min-height: size(200px);
    position: relative;
    margin-bottom: size(12px);
  }

  &__table {
    width: 100%;
    height: 100%;
    min-height: size(200px);
    position: relative;
    border-radius: size(12px);
    display: flex;
    flex-direction: column;

    & .table-pagination {
      margin-bottom: 0;
      border-radius: size(12px) size(12px) 0 0;
    }
  }
  &__diagram {
    padding: size(10px);
    width: 100%;
  }

  &-loader {
    display: flex;
    align-items: center;
    width: 100%;
    min-height: size(300px);
    position: relative;
    margin-bottom: size(12px);
  }
}