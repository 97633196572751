@import '../../../../../styles/variables.scss';
@import '../../../../../styles/functions.scss';
@import '../../../../../styles/mixins.scss';

.table-funnel {
  &-labels {

    &-item {
      font-weight: 500;
      font-size: size(14px);
      color: $color-rock-blue;
      display: flex;
      align-items: center;
      //width: size(150px);
      //max-width: size(150px);
      position: relative;
      padding: 0 size(17px);
      background: $color-ghost-white;

      &.sticky {
        position: sticky;
        z-index: 1;
        left: 0;
      }

      &__title {
        display: flex;
        align-items: center;
        width: 100%;
        position: relative;
        padding: size(12px) size(6px);

        svg {
          min-width: size(10px);
        }

        &:hover .table-label-item__filter,
        &:hover .table-label-item__pin {
          opacity: 1;
        }

        &.left .select-options.active,
        &.left .number-sort-modal.active {
          right: auto;
          left: 0;
        }

        &-text {
          position: relative;

          & ~ .alt {
            display: flex;
            top: size(-5px);
          }
        }
      }
    }
  }
}